import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import CoursesGraph from './CoursesGraph';
import './App.css';

const App = () => {
    const [graphData, setGraphData] = useState({ nodes: [], edges: [] });
    const [inputValue, setInputValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [searchType, setSearchType] = useState('major');
    const wrapperRef = useRef(null);

    const classCodesFullNames = {
        ACAD: "Academic Success Resources",
        ACCT: "Accountancy",
        ADST: "Addiction Studies",
        AE: "Architectural Engineering",
        AHS: "Allied Health Sciences",
        AMI: "Advanced Medical Imaging",
        ANTH: "Anthropology",
        ARABIC: "Arabic",
        ART: "Art",
        ARTHIST: "Art History",
        ARTSCI: "Art-Science Integration",
        ASL: "American Sign Language",
        BAS: "Bachelor of Applied Science",
        'BASQ-STD': "Basque Studies",
        BASQUE: "Basque Studies",
        BIOCHEM: "Biochemistry",
        BIOL: "Biology",
        BME: "Biomedical Engineering",
        BMOL: "Biomolecular Sciences",
        BOSNIAN: "Bosnian",
        BOT: "Botany",
        BRNCOFIT: "Bronco Fit",
        BUS: "Business",
        BUSCOM: "Business Communication",
        BUSMBA: "Masters of Business Administration",
        BUSMGT: "Business Management",
        BUSSTAT: "Business Statistics",
        CANSTD: "Canadian Studies",
        CE: "Civil Engineering",
        CEEC: "CE-EC",
        CHEM: "Chemistry",
        CHINESE: "Chinese",
        CHP: "Center for Health Policy",
        CJ: "Criminal Justice",
        CMGT: "Construction Management",
        COID: "College of Innovation + Design",
        COMM: "Communication",
        COMPUT: "Computing",
        CONFLICT: "Conflict Management",
        CORE: "Cyber Operations and Resilience",
        COUN: "Counseling",
        CPS: "Cyber Physical Systems Security",
        CRIT: "Critical Theory",
        CS: "Computer Science",
        CSE: "Computer Systems Engineering",
        CW: "Creative Writing",
        CYBER: "Cybersecurity",
        'DATA-R': "Data Science and Analytics",
        DCM: "Design and Construction Management",
        ECE: "Electrical and Computer Engineering",
        'ED-CIFS': "Curriculum, Instruction, and Foundational Studies",
        'ED-ESP': "Education, Special Education",
        'ED-LLC': "Literacy, Language, and Culture",
        EDTECH: "Educational Technology",
        EDU: "Education",
        EEB: "Ecology, Evolution, and Behavior",
        EMBA: "Executive Masters of Business Administration",
        ENGLIT: "English Literature",
        ENTBUS: "Entrepreneurial Business",
        ENTREP: "Entrepreneurship",
        ENVSCI: "Environmental Science",
        ENVSTD: "Environmental Studies",
        EOHS: "Environmental and Occupational Health Sciences",
        ETHNIC: "Ethnic Studies",
        EXPER: "Experiential Learning",
        FILM: "Film",
        FINAN: "Finance",
        FREN: "French",
        GCOLL: "General College",
        GENCOUN: "General Counseling",
        GENDER: "Gender Studies",
        GENSCI: "General Science",
        GEOG: "Geography",
        GEOL: "Geology",
        GEOPH: "Geophysics",
        GEOS: "Geosciences",
        GERM: "German",
        GIMM: "Imaging and Intervention",
        GLOBAL: "Global Studies",
        HCS: "Health Care Studies",
        HEP: "Health Education and Promotion",
        HES: "Health and Exercise Science",
        HIIM: "Health Informatics and Information Management",
        HIST: "History",
        HLTH: "Health",
        HONORS: "Honors",
        HRM: "Human Resource Management",
        HUM: "Humanities",
        IDS: "Interdisciplinary Studies",
        IEPATH: "Interdisciplinary Engineering and Applied Technology Pathway",
        IFITS: "Integrated Foundational and Industrial Technologies and Systems",
        IMGSCI: "Imaging Science",
        INTBUS: "International Business",
        IPS: "Interdisciplinary Professional Studies",
        ISLE: "Intensive Studies in Languages and Cultures of the English-Speaking World",
        ITM: "Information Technology Management",
        JAPANESE: "Japanese",
        JOUR: "Journalism",
        'KIN-AL': "Kinesiology - Athletic Leadership",
        KINES: "Kinesiology",
        KOREAN: "Korean",
        LATIN: "Latin",
        LEAD: "Leadership",
        LIBR: "Library Science",
        LING: "Linguistics",
        MAT: "Mathematics",
        MATHED: "Mathematics Education",
        MBA: "Masters of Business Administration",
        ME: "Mechanical Engineering",
        MEDIA: "Media Studies",
        MEDIAPRO: "Media Production",
        MEM: "Masters of Engineering Management",
        MHLTHSCI: "Masters of Health Science",
        MILSCI: "Military Science",
        MKTG: "Marketing",
        MPH: "Masters of Public Health",
        MSE: "Materials Science and Engineering",
        MUS: "Music",
        'MUS-APL': "Music - Applied",
        'MUS-ENS': "Music - Ensemble",
        'MUS-PRV': "Music - Private Lessons",
        MUSI: "Music",
        NEURO: "Neuroscience",
        NONPROF: "Nonprofit Management",
        NURS: "Nursing",
        'NURS-DNP': "Nursing Doctor of Nursing Practice",
        'NURS-RN': "Nursing for Registered Nurses",
        OPWL: "Organizational Performance and Workplace Learning",
        PHIL: "Philosophy",
        PHSM: "Public Health and Social Media",
        PHYS: "Physics",
        POLS: "Political Science",
        PORTUGUE: "Portuguese",
        PPHL: "Preprofessional Health",
        PR: "Public Relations",
        PRO: "Professional",
        PROJMGT: "Project Management",
        PSYC: "Psychology",
        PUBADM: "Public Administration",
        PUBH: "Public Health",
        RADSCI: "Radiologic Sciences",
        REFUGEE: "Refugee Studies",
        RESPCARE: "Respiratory Care",
        RHM: "Resort, Hotel, and Restaurant Management",
        SCM: "Supply Chain Management",
        SIC: "Science in Cinema",
        SOC: "Sociology",
        SOCWRK: "Social Work",
        SPAN: "Spanish",
        SPS: "Sport, Fitness, and Sport Science",
        'STEM-ED': "STEM Education",
        THEA: "Theatre Arts",
        UF: "University Foundations",
        URBAN: "Urban Studies",
        UX: "User Experience",
        'UX-PRO': "User Experience Professional",
        VIP: "Vertically Integrated Projects",
        WORLD: "World Languages",
        WRITE: "Writing",
        ZOOL: "Zoology"
    };

    useEffect(() => {
        const fetchData = async () => {
            let query = '';
            if (searchType === 'major' && inputValue) {
                query = `?major=${inputValue}`;
            } else if (searchType === 'course' && inputValue) {
                query = `?code=${inputValue}`;
            }

            const cacheKey = `graphData-${inputValue}-${searchType}`;
            const cachedData = localStorage.getItem(cacheKey);
            if (cachedData) {
                setGraphData(JSON.parse(cachedData));
                return;
            }
            console.log(query);

            try {
                const result = await axios(`/api/boisestate${query}`);
                const cleanedData = transformAndCleanData(result.data);
                setGraphData(cleanedData);

                localStorage.setItem(cacheKey, JSON.stringify(cleanedData));
                console.log(cleanedData);
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };

        if (inputValue) {
            fetchData();
        }
    }, [inputValue, searchType]);

    function transformAndCleanData(rawData) {
        const uniqueNodes = Array.from(new Map(rawData.nodes.map(node => [node.id, node])).values());
        const cleanedEdges = rawData.edges.map(edge => {
            const fromNode = uniqueNodes.find(node => node.id === edge.from);
            const toNode = uniqueNodes.find(node => node.id === edge.to);

            if (fromNode && toNode) {
                return {
                    ...edge,
                    from: fromNode.id,
                    to: toNode.id,
                };
            }

            return null;
        }).filter(edge => edge !== null);

        return { nodes: uniqueNodes, edges: cleanedEdges };
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowOptions(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const handleInputChange = (e) => {
        const input = e.target.value.toUpperCase();
        setInputValue(input);
        const looksLikeCourse = input.match(/\d+/);
        setSearchType(looksLikeCourse ? 'course' : 'major');

        let filtered;
        if (searchType === 'major') {
            filtered = Object.keys(classCodesFullNames).filter(code =>
                code.startsWith(input) || classCodesFullNames[code].toUpperCase().includes(input)
            );
        } else {
            // Filter based on course codes
            filtered = Object.keys(classCodesFullNames).filter(code =>
                code.startsWith(input)
            );
        }

        setFilteredOptions(filtered);
        setShowOptions(true);
    };

    const handleSelectOption = (code) => {
        setInputValue(code);
        const looksLikeCourse = code.match(/\d+/);
        setSearchType(looksLikeCourse ? 'course' : 'major');
        setShowOptions(false);

    };

    return (
        <div ref={wrapperRef} style={{ position: 'relative', width: '100vw', height: '100vh', overflow: 'hidden' }}>
            <input
                className="dropdown-input"
                value={inputValue}
                onChange={handleInputChange}
                onClick={() => setShowOptions(!showOptions)}
                onFocus={() => setFilteredOptions(Object.keys(classCodesFullNames))}
                placeholder="Type or select a class code"
            />

            {showOptions && (
                <ul className="dropdown-options">
                    {filteredOptions.map((code) => (
                        <li key={code} onClick={() => handleSelectOption(code)} className="dropdown-option">
                            {`${classCodesFullNames[code]}`}
                        </li>
                    ))}
                </ul>
            )}

            <CoursesGraph data={graphData} />

            <div id="tooltip"></div>
        </div>
    );
};

export default App;
