import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';

const CoursesGraph = ({ data }) => {
    const d3Container = useRef(null);
    const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [tooltip, setTooltip] = useState({ display: false, data: {}, x: 0, y: 0 });
    const isDragging = useRef(false);

    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (data && data.nodes && data.edges && d3Container.current) {
            const svg = d3.select(d3Container.current)
                .attr('viewBox', `0 0 ${dimensions.width} ${dimensions.height}`)
                .style('cursor', 'grab');

            svg.selectAll("*").remove(); // Clear previous contents


            const uniqueIds = new Set(data.nodes.map(node => node.id));
            if (uniqueIds.size !== data.nodes.length) {
                console.error('Duplicate nodes detected');
            }

            // Create a new array without duplicates based on node ID
            const cleanNodes = Array.from(new Map(data.nodes.map(node => [node.id, node])).values());

            const duplicates = data.nodes.filter((item, index, array) => array.findIndex(i => i.id === item.id) !== index);
            console.log('Duplicate nodes:', duplicates);




            const container = svg.append("g");

            const zoom = d3.zoom()
                .scaleExtent([1, 5])
                .on('zoom', (event) => container.attr('transform', event.transform));
            svg.call(zoom);

            svg.on('dblclick.zoom', null);

            svg.on('click', () => {
                if (!isDragging.current) {
                    setTooltip({ display: false, data: {}, x: 0, y: 0 });
                }
            });

            const drag = d3.drag()
                .on("start", function(event, d) {
                    if (!event.active) simulation.alphaTarget(0.3).restart();
                    d.fx = d.x;
                    d.fy = d.y;
                    isDragging.current = true;
                    // Check if the event is a click or drag
                    if (event.sourceEvent.type === "mousedown") {
                        svg.on('.zoom', null); // Disable zoom during click
                    }

                })
                .on("drag", function(event, d) {
                    d.fx = event.x;
                    d.fy = event.y;
                })
                .on("end", function(event, d) {
                    if (!event.active) simulation.alphaTarget(0);
                    d.fx = null;
                    d.fy = null;
                    isDragging.current = false;
                    // Re-enable zoom after drag ends
                    svg.call(zoom);
                });

            const transformedEdges = data.edges.map(edge => ({
                source: cleanNodes.find(node => node.id === edge.from),
                target: cleanNodes.find(node => node.id === edge.to),
                label: edge.label // Add label property
            })).filter(edge => edge.source && edge.target);

            const simulation = d3.forceSimulation(cleanNodes)
                .force('link', d3.forceLink(transformedEdges).id(d => d.id).distance(100))
                .force('charge', d3.forceManyBody().strength(-50))
                .force('center', d3.forceCenter(dimensions.width / 2, dimensions.height / 2))
                .force('x', d3.forceX().strength(0.01).x(dimensions.width*2)) // Horizontal constraint
                .force('y', d3.forceY().strength(0.01).y(dimensions.height*2)); // Vertical constraint

            svg.append('defs').append('marker')
                .attr('id', 'arrowhead')
                .attr('viewBox', '-0 -5 10 10')
                .attr('refX', 15)
                .attr('refY', 0)
                .attr('orient', 'auto')
                .attr('markerWidth', 10)
                .attr('markerHeight', 10)
                .attr('xoverflow', 'visible')
                .append('svg:path')
                .attr('d', 'M 0,-5 L 10 ,0 L 0,5')
                .attr('fill', '#999')
                .style('stroke', 'none');

            const linkGroup = container.append('g');

            const link = linkGroup.selectAll('line')
                .data(transformedEdges)
                .join('line')
                .attr('stroke', d => {
                    // Set stroke color based on link type
                    if (d.label === 'PREREQUISITE_FOR') {
                        return '#00ff00'; // green
                    } else if (d.label === 'COREQUISITE_FOR') {
                        return '#ff0000'; // red
                    } else {
                        return '#0000ff'; // blue
                    }
                })
                .attr('stroke-width', d => Math.sqrt(d.value))
                .attr('marker-end', 'url(#arrowhead)')
                .on('mouseover', (event, d) => {
                    // Calculate the angle of the edge
                    const dx = d.target.x - d.source.x;
                    const dy = d.target.y - d.source.y;
                    let angle = Math.atan2(dy, dx) * 180 / Math.PI;

                    // Ensure the angle is positive
                    if (angle < 0) {
                        angle += 360;
                    }

                    // Adjust the rotation angle based on the quadrant
                    if (angle > 90 && angle <= 270) {
                        angle += 180;
                    }

                    linkGroup.append('text')
                        .attr('class', 'relationship-label')
                        .attr('x', (d.source.x + d.target.x) / 2)
                        .attr('y', (d.source.y + d.target.y) / 2)
                        .attr('dy', -5)
                        .attr('text-anchor', 'middle')
                        .attr('font-size', '12px')
                        .attr('transform', `rotate(${angle}, ${(d.source.x + d.target.x) / 2}, ${(d.source.y + d.target.y) / 2})`)
                        .text(d.label);
                })


                .on('mouseout', (event, d) => {
                    svg.selectAll('.relationship-label').remove();
                });

            const nodeGroup = container.append('g');

            const tooltip = d3.select("#tooltip");

            const node = nodeGroup.selectAll('circle')
                .data(cleanNodes)
                .join('circle')
                .attr('r', 5)
                .attr('fill', d => d.group)
                .call(drag)
                .on('click', (event, d) => {
                    event.stopPropagation();
                    setTooltip({
                        display: true,
                        data: d,
                        x: event.pageX,
                        y: event.pageY
                    });
                })


                .on('mouseover', (event, d) => {
                    const linkedNodes = new Set(); // Create a new set for linked nodes
                    const linkedEdges = new Set(); // Create a new set for linked edges

                    // Find linked nodes and edges recursively
                    findLinkedNodes(d.id, linkedNodes, linkedEdges);

                    // Highlight only the linked nodes and edges
                    nodeGroup.selectAll('circle')
                        .attr('opacity', nodeData => linkedNodes.has(nodeData.id) ? 1 : 0.3);

                    linkGroup.selectAll('line')
                        .attr('opacity', linkData => linkedEdges.has(linkData) ? 1 : 0.3);

                // Show relationship labels for linked edges
                    linkedEdges.forEach(edge => {
                        const angle = Math.atan2(edge.target.y - edge.source.y, edge.target.x - edge.source.x) * 180 / Math.PI;

                        let labelText = '';
                        switch (edge.label) {
                            case 'PREREQUISITE_FOR':
                                labelText = 'Prerequisite';
                                break;
                            case 'COREQUISITE_FOR':
                                labelText = 'Corequisite';
                                break;
                            default:
                                labelText = 'Other Relationship';
                        }

                        linkGroup.append('text')
                            .attr('class', 'relationship-label')
                            .attr('x', (edge.source.x + edge.target.x) / 2)
                            .attr('y', (edge.source.y + edge.target.y) / 2)
                            .attr('dy', -5)
                            .attr('text-anchor', 'middle')
                            .attr('font-size', '12px') // Change text size here (e.g., '12px')
                            .attr('transform', `rotate(${angle}, ${(edge.source.x + edge.target.x) / 2}, ${(edge.source.y + edge.target.y) / 2})`)
                            .text(labelText);
                    });
                    d3.select(event.target)
                        .attr('opacity', 1);
            });

            const findLinkedNodes = (nodeId, linkedNodes, linkedEdges, visited = new Set()) => {
                if (visited.has(nodeId)) return;
                visited.add(nodeId);

                // Find linked nodes and edges recursively through prerequisites and corequisites
                transformedEdges.forEach(edge => {
                    if (edge.target.id === nodeId) {
                        linkedEdges.add(edge);
                        linkedNodes.add(edge.source.id);
                        findLinkedNodes(edge.source.id, linkedNodes, linkedEdges, visited);
                    }
                });
            };

            node.on('mouseout', () => {
                node.attr('opacity', 1);
                link.attr('opacity', 1);
                svg.selectAll('.relationship-label').remove();
            });

            simulation.on('tick', () => {
                link.attr('x1', d => d.source.x)
                    .attr('y1', d => d.source.y)
                    .attr('x2', d => d.target.x)
                    .attr('y2', d => d.target.y);

                node.attr('cx', d => d.x)
                    .attr('cy', d => d.y);
            });
        }
    }, [data, dimensions]);

    return (
        <>
            <svg ref={d3Container} width={dimensions.width} height={dimensions.height}>
                {}
            </svg>
             {tooltip.display && (
                 <div
                     className="tooltip"
                     style={{
                         left: `${tooltip.x}px`,
                         top: `${tooltip.y}px`,
                         opacity: 1
                     }}
                 >
                     {}
                     CourseCode: {tooltip.data.code} - ({tooltip.data.credits})<br/>
                     Name: {tooltip.data.title}<br/>
                     Description: {tooltip.data.description}<br/>
                     Prereq: {tooltip.data.prerequisites}<br/>
                     Coreq: {tooltip.data.corequisites}<br/>
                     Level: {tooltip.data.courseLevel}<br/>
                     <button onClick={() => setTooltip({display: false, data: {}, x: 0, y: 0})}
                             style={{marginLeft: "10px"}}>
                         Close
                     </button>
                 </div>
             )}
        </>
    );
};

export default CoursesGraph;
